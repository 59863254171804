import "./ResultWindow.scss";
import PageErrorIcon from "../../assets/icons/PageErrorIcon.svg";
import PageSuccessIcon from "../../assets/icons/PageSuccessIcon.svg";
import Button from "@mui/material/Button";

function ResultWindow({ icon, status, description, onClose }) {
  return (
    <div className="result-window">
      <div className="result-window__icon">
        <img src={icon} alt="window-icon" />
      </div>
      <p className="result-window__status">{status}</p>
      <p className="result-window__description">{description}</p>
      <Button variant="contained"
      sx={{fontWeight: 'normal', fontSize: '14px', lineHeight: '24px', textTransform: 'none'}}
      onClick={()=>onClose(null)}>
        Back Home
      </Button>
    </div>
  );
}

export function SuccessResultWindow({ onClose }) {
  return (
    <div className="result-window-wrapper">
      <ResultWindow
        icon={PageSuccessIcon}
        status={"Success!"}
        description={`Your report(s) submission is completed.
           We will send you the processing results in a few minutes. Please check your email.`}
        onClose={onClose}
      />
    </div>
  );
}

export function WrongResultWindow({ onClose }) {
  return (
    <div className="result-window-wrapper">
      <ResultWindow
        icon={PageErrorIcon}
        status={"Submission Failed"}
        description={"Something went wrong. Please resubmit your report(s)."}
        onClose={onClose}
      />
    </div>
  );
}
