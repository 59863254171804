  /* OneTrust Cookies Consent Notice start for cspartner.bayer.com */
  const cookiesConsentNoticeScript = () => {
    const script = document.createElement('script');
    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.setAttribute('data-domain-script', '01919e75-2c08-7f9d-b689-4d0f025c930a');
    script.async = true;
    document.body.appendChild(script);
  };

  export default cookiesConsentNoticeScript;