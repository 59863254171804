import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {  autorizeUser } from "../api/gigyaApi"

const Proxy = ({onSetCorrectData, onSetUserData}) => {
  const [code, setCode] = useState('');
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  function setUserInfoToLocalStorage(accessToken, refreshToken, email) {
    const userInfo = { accessToken, refreshToken,  email };
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  }

  useEffect(() => {
    // Extract code from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const codeParam = queryParams.get('code');
    setCode(codeParam);
  }, []);

  useEffect( () => {
    // Fetch data using the extracted code
    async function autorization(){
      if (code) {
      const userInfo = await autorizeUser(code);
      setUserInfoToLocalStorage(userInfo?.access_token, userInfo?.refresh_token, userInfo?.email);
      onSetCorrectData("OK");
      onSetUserData({email: userInfo?.email, uid: userInfo?.uid});
      navigate('/', { replace: true });
      setData(userInfo);
      }
    }

    autorization();
  }, [code]);

  return (
  <></>
  );
};

export default Proxy;
