import { Outlet } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/FooterComponent/FooterComponent"
import "./BaseLayout.scss";

export default function BaseLayout({ userProfile, onSignOut }) {
  return (
    <div className="layout">
      <Header userProfile={userProfile} onSignOut={onSignOut} />
      <Outlet />
      <Footer/>
    </div>
  );
}
