import { useCallback, useLayoutEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSessionStorage } from "../hooks/useSessionStorage";
import { isEmptyObject } from "../utils/isEmptyObject";
import { noop } from "../utils/noop";
import { timeout } from "../utils/timeout";
import { IS_REDIRECT_KEY, LOGIN_START_SCREEN } from "../constants/additional";
import "./LoginPage.scss";
import { Container } from "./login-styles";
import LogoIcon from "../assets/icons/Logos.svg";
import LogoImage from "../img/Image Container (1).png";
import Footer from "components/FooterComponent/FooterComponent";

const TIME_DELAY_FOR_PAGE_REDIRECT = 300000; // 5 min delay

const useGigyaSetup = () => {
  const navigate = useNavigate();
  const [searchParams, setParams] = useSearchParams();

  const [getGigyaCache, removeGigyaCache] = useSessionStorage(IS_REDIRECT_KEY);
  const [getStartScreenFromStore, setStartScreenToStore] =
    useSessionStorage(LOGIN_START_SCREEN);

  const [startScreen, setStartScreen] = useState("bayer-login-nosc");

  const gigyaCache = getGigyaCache();
  const screen = searchParams.get("screen");

  function setEmptyGigyaCache() {
    const key = "gigyaCache";
    const value = JSON.stringify({}); // Convert the empty object to a JSON string
    sessionStorage.setItem(key, value);
  }

  useLayoutEffect(() => {
    if (startScreen) {
      Promise.resolve()
        .then(() => {
          const hasLoginRedirectKey =
            gigyaCache &&
            gigyaCache instanceof Object &&
            !isEmptyObject(gigyaCache);

          if (hasLoginRedirectKey) {
            throw Error("Has redirect!");
          }
        })
        .then(() => {
          /*
           * Set settings to gigyaCache(take a look at sessionStorage).
           * Should be initialized before login process (before press login button).
           */
          const url = process.env.REACT_APP_GIGYA_URL;
          const apiKey = process.env.REACT_APP_GIGYA_API_KEY;
          const clientId = process.env.REACT_APP_GIGYA_CLIENT_ID;
          const redirectUrl = `${process.env.REACT_APP_LOGIN_REDIRECT_URL}`;
          const newNonce = Date.now();

          window.location.href = `${url}/${apiKey}/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUrl}&scope=openid%20email%20profile&nonce=${newNonce}`;
        })
        .catch(noop);
    }
  }, [startScreen]);

  const firstLoginHandler = useCallback(
    (eventObj) => {
      if (startScreen) {
        const { hostname } = window.location;
        const timestamp = new Date().toISOString(); // please convert the timestamp to UTC if your server is not in UTC

        const apps = eventObj.data.bc_accessApplications || []; // if application list empty, create new object

        let isNewApp = true;

        // is app new or already in the apps list?
        apps.forEach((appItem, idx, appList) => {
          const appInfo = JSON.parse(appItem);

          // search for app in the array
          if (appInfo.hostname === hostname) {
            isNewApp = false;
            // app is already in array, actualize timestamp in lastLogin
            appInfo.lastLogin = timestamp;
            appList[idx] = JSON.stringify(appInfo);
          }
        });

        if (isNewApp) {
          const loginInfo = {
            hostname,
            firstLogin: timestamp,
            lastLogin: timestamp,
          };

          // app is new -> new entry in array
          apps.push(JSON.stringify(loginInfo));
        }

        // update apps list on server
        window.gigya.accounts.setAccountInfo({
          data: {
            bc_accessApplications: apps,
          },
        });

        // Redirect to proxy
        window.location.href = window.gigya.utils.URL.addParamsToURL(
          "proxy.html",
          {
            mode: "afterLogin",
          }
        );
      }
    },
    [startScreen]
  );

  useLayoutEffect(() => {
    Promise.resolve()
      .then(() => {
        const hasLoginRedirectKey =
          gigyaCache &&
          gigyaCache instanceof Object &&
          !isEmptyObject(gigyaCache);

        if (!hasLoginRedirectKey) {
          console.log("gigyaCache is empty!");
          throw Error("No redirect!");
        }
      })
      /*
       * Login process (after press login button)
       */
      .then(() => {
        window.gigya.accounts.addEventHandlers({
          onLogin: firstLoginHandler,
        });
      })
      .then(() => {
        window.gigya.socialize.addEventHandlers({
          onLogin: (event) => {
            console.log(event, window.gigya.socialize, "imp1");
            //   Redirect to proxy
            window.location.href = window.gigya.utils.URL.addParamsToURL(
              "proxy.html",
              {
                mode: "afterLogin",
              }
            );
          },
        });
      })
      .then(() => {
        window.gigya.accounts.showScreenSet({
          screenSet: "bayer-RegistrationLogin",
          startScreen,
          containerID: "container",
        });
      })
      .catch(noop);
  }, [startScreen]);

  // this is need for correct opening of registration screen at first start Login, when gigya send request for getting gigyaCache
  useLayoutEffect(() => {
    if (startScreen) {
      (async () => {
        if (!gigyaCache) {
          await timeout(2000);
        }
        setStartScreenToStore("bayer-login-nosc");
      })();
    }
  }, [startScreen]);

  useLayoutEffect(() => {
    const timerIdList = [];

    (async () => {
      await timeout(TIME_DELAY_FOR_PAGE_REDIRECT, timerIdList);
      setEmptyGigyaCache();
      navigate("/");
    })();

    return () => {
      setEmptyGigyaCache();
      timerIdList.forEach(clearTimeout);
    };
  }, []);
};

const LoginPage = () => {
  useGigyaSetup();

  return (
    <div className="login-content-wrapper">
      <div className="login-content">
        <div className="login-wrapper">
          <div className="login-logo">
            <img src={LogoIcon} alt="logo" className="login-logo__icon" />
            <span className="login-logo__text">Bayer CISO Toolbox</span>
          </div>
          <h1 className="login-header">Log in to your account</h1>
          <Container id="container" />
        </div>
        <div className="login-image">
          <img src={LogoImage} alt="login page image" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginPage;
