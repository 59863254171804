import React from "react";
import "./FooterComponent.scss"; // Import the styles into your component

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-wrapper">
        <p>Copyright © Bayer AG</p>
        <nav className="footer-navigation" aria-label="Footer Navigation">
          <ul className="footer-menu">
            <li>
              <a href="https://www.bayer.com/en/conditions-of-use">Conditions of Use /</a>
            </li>
            <li>
              <a href="/privacy-statement">Privacy Statement /</a>
            </li>
            <li>
              <a href="https://www.bayer.com/en/imprint">Imprint</a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;