export const IS_DEVELOPMENT_MODE = !!+process.env.REACT_APP_IS_DEVELOPMENT_MODE;

export const TYPE_STATUS = [
    { id: 1, name: 'Pending', message: 'pending' },
    { id: 2, name: 'Approved', message: 'approved' },
    { id: 3, name: 'Rejected', message: 'rejected' },
    { id: 4, name: 'Archived', message: 'needsFix' },
];

export const ASC = 'asc';
export const DESC = 'desc';
export const NUMBERS_ROWS_LIST = [10, 20, 30, 40];
export const LOCALE = 'ru';
export const SMALL = 'small';
export const WIDTH_OFFSET = 17;
export const DEBOUNCE_DELAY = 200;
export const MAX_NUMBER_ORGANIZATION = 10000;
export const HIDDEN_FIELD = '*****';
export const EMPTY_FIELD = '-';

export const CommentMaxLength = {
    ORGANIZATIONS: 500,
    INVOICES: 500,
    REWARDS: 500,
    JOIN_REQUESTS: 256,
    PARTICIPANTS: 500,
    POINTS_ADJUSTMENT: 1000,
    PROMOTIONS: 512,
};

export const POSITION_ID = {
    DIRECTOR: 2,
    CUSTOM: 16,
    HOLDING_REPRESENTATIVE: 17,
};
export const OrganizationFileTypesMap = {
    1: {
        fileTypeId: 1,
        name: 'attorney',
    },
    2: {
        fileTypeId: 2,
        name: 'confirmationDocType',
    },
    3: {
        fileTypeId: 3,
        name: 'otherDocType',
    },
    4: {
        fileTypeId: 4,
        name: 'profileImage',
    },
};

export const RepresentativeFileTypesMap = {
    1: {
        fileTypeId: 1,
        name: 'otherDocType',
    },
    2: {
        fileTypeId: 2,
        name: 'confirmationDocType',
    },
    3: {
        fileTypeId: 3,
        name: 'attorney',
    },
};

export const OrganizationFileTypesId = {
    confirmationFiles: 1,
    otherFiles: 2,
    profileImage: 3,
    holdingCreationRequestFiles: 4,
    participationFiles: 5,
    pointsFiles: 6,
};

export const RepresentativeFileTypesId = {
    otherFiles: 1,
    confirmationFiles: 2,
    attorneyFiles: 3,
};

export const InvoiceFileTypesId = {
    invoiceFiles: 1,
    approveVolumeFiles: 2,
    returnInvoiceFiles: 3,
    selloutInvoiceFiles: 4,
};

export const FILE_ENTITY_TYPE = {
    PARTICIPATION_FILES: 'participationFiles',
    POINTS_FILES: 'pointsFiles',
    ATTORNEY_FILES: 'attorneyFiles',
    CONFIRMATION_FILES: 'confirmationFiles',
    OTHER_FILES: 'otherFiles',
    PROFILE_IMAGE: 'profileImage',
    INVOICE_FILES: 'invoiceFiles',
    APPROVE_VOLUME_FILES: 'approveVolumeFiles',
    ACT_DELIVERY: 'actDelivery',
    RETURN_INVOICE: 'returnInvoice',
    SELLOUT_INVOICE: 'selloutInvoice',
};

export const FILE_OWNER = {
    REPRESENTATIVE: 'representative',
    ORGANIZATION: 'organization',
};

export const FILE_ORDER_TYPE = {
    POA: 'PoA',
    ACT: 'Act',
};

export const EDIT = 'edit';

export const ORGANIZATION_TYPE_LIST = [
    { id: 'farm', name: 'typeFarm' },
    { id: 'holding', name: 'typeHolding' },
    { id: 'group', name: 'typeGroup' },
];

export const ORGANIZATION_TYPE = {
    FARM: 'farm',
    SIMPLE_FARM: 'simplifiedFarm',
    GROUP: 'group',
    HOLDING: 'holding',
};

export const MESSAGE_POPUP_STATUSES = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
};

export const SEARCH_PARAM_REDIRECT_TO_CART = 'shouldRedirectToCart';

export const TYPE_CONTACT_PERSON = {
    FARM: 'farm',
    DISTRIBUTOR: 'distributor',
};

// for login
export const IS_REDIRECT_KEY = 'gigyaCache';
export const LOGIN_START_SCREEN = 'loginStartScreen';
export const LOGIN_SYSTEM_POPUP_KEY = 'loginSystemPopupOpen';

export const RESPONSE_STATUSES = {
    FULFILLED: 'fulfilled',
    REJECTED: 'rejected',
};

export const STORAGE_KEY = {
    NOT_SHOW_MIGRATION_POPUP: 'notShowMigrationPopup',
    MAINTENANCE: 'developer',
};

export const GENDER = {
    MALE: 'male',
    FEMALE: 'female',
};

export const USER_ROLE = {
    FARMER: 'farmer',
    TM: 'tm',
};

export const FileErrors = {
    FILE_INVALID_TYPE: 'file-invalid-type',
    FILE_TOO_LARGE: 'file-too-large',
    TOO_MANY_FILES: 'too-many-files',
    FILE_NAME_CONTAINS_INVALID_CHARACTERS: 'file-name-contains-invalid-characters',
};

export const LogoType = {
    IMAGE_ID: 'imageId',
    IMAGE_URL: 'imageUrl',
};
