import "./App.css";
import FileUpload from "./components/FileLoader/FileLoader";
import { useLayoutEffect, useState, useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import ErrorPage from "../src/pages/ErrorPage";
import BaseLayout from "./layout/BaseLayout";
import LoginPage from "./pages/LoginPage";
import ConsentPage from "./pages/ConsentPage";
import RegisterPage from "./pages/RegisterPage";
import CircularIndeterminate from "./mui-components/CircularIndeterminate";
import Proxy from "./pages/Proxy";
import {
  removeUserInfoFromLocalStorage,
} from "../src/utils/localStorageInterect";
import TestLoginPage from "../src/pages/TestLoginPage";
import ConsentStatement from "../src/pages/consent-statement/ConsentStatement";
import cookiesConsentNoticeScript from "services/cookiesConsentNoticeScript";
import validateSession from "services/authUser";

function App() {
  const [userProfile, setUserProfile] = useState({});
  const [isSessionActive, setSessionStatus] = useState(null);

  useEffect(()=>{
    const gigyaCache = sessionStorage.getItem('gigyaCache');

    if(!!gigyaCache){
      cookiesConsentNoticeScript();
    } 
  })

  function signOut() {
    removeUserInfoFromLocalStorage();
    setSessionStatus("FAIL");
    window?.gigya.accounts.logout(userProfile?.uid);
  }

  useLayoutEffect(() => {
    validateSession(setSessionStatus, setUserProfile);
  }, []);

  return (
    <BrowserRouter>
      {isSessionActive === null ? (
        <CircularIndeterminate />
      ) : (
        <Routes>
          <Route
            path="/login"
            element={<LoginPage onLogIn={setUserProfile} />}
          />
          <Route path="/consent" element={<ConsentPage />} />
          <Route
            path="/proxy"
            element={
              <Proxy
                onSetCorrectData={setSessionStatus}
                onSetUserData={setUserProfile}
              />
            }
          />
          <Route path="/proxy.html" element={<div></div>} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/test-login" element={<TestLoginPage />} />
          <Route path="/privacy-statement" element={<ConsentStatement />} />
          <Route
            path="/"
            element={
              <RequireAuth status={isSessionActive}>
                <BaseLayout
                  onSignOut={signOut}
                  userProfile={userProfile}
                ></BaseLayout>
              </RequireAuth>
            }
          >
            <Route index element={<Navigate to="/send-reports" replace />} />
            <Route
              path="/send-reports"
              element={<FileUpload userProfile={userProfile} />}
            />
            <Route path="/history" element={<div>History</div>} />
            <Route
              path="/*"
              element={<Navigate to="/send-reports" replace />}
            />
          </Route>
        </Routes>
      )}
    </BrowserRouter>
  );
}

function RequireAuth({ children, status }) {
  let location = useLocation();
  if (status === 'FAIL') {
     return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (status === "OK") {
    console.log("show childrens");
    return children;
  }
}

export default App;
