import './Dropzone.scss';
import React, {useCallback} from 'react';
import { useDropzone } from 'react-dropzone';
import DropZoneIcon from "../../assets/icons/DropZoneIcon.svg"

function MyDropzone({onDropFiles}) {
  

  const onDrop = useCallback(acceptedFiles => {
    onDropFiles(acceptedFiles)
  }, [])

  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <div className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <div className='dropzone-content'>
          <div className='dropzone-content__icon'><img src={DropZoneIcon} alt="file-icon"/></div>
          <p className='dropzone-content__s'>
          <span className='dropzone-content__link'>Click to upload</span>{" "}<span>or drag and drop</span>
          </p>
          <p className='dropzone-content__specification'>XLS, XLSX, CSV files (max. 15MB)</p>
        </div>
      </div>
    </div>
  );
}

export default MyDropzone;
