import "./FileCard.scss";
import DeleteFilled from "../../assets/icons/DeleteFilled.svg";
import DropZoneIcon from "../../assets/icons/DropZoneIcon.svg";
import LinearWithValueLabel from "../../mui-components/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function FileCard({ fileName, size, isError, onRemove, LoadStatus }) {
  return (
    <div className="file">
      <div className="file__icon">
        {LoadStatus === "LOAD" ? (
          <Box
            sx={{
              width: "28px",
              height: "28px",
              marginRight: "10px"
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <img src={DropZoneIcon} alt="file-icon" />
        )}
      </div>
      <div className="file__description">
        <p className="file__name">{fileName}</p>
        <p className="file__size">
          {size / 1000}
          {"kb"}
        </p>
        <div className="file__progress-line">
          <LinearWithValueLabel />
        </div>
      </div>
      <div className="file__remove-icon" onClick={() => onRemove(fileName)}>
        <img src={DeleteFilled} alt="remove-icon" />
      </div>
    </div>
  );
}

export default FileCard;
