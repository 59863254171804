export function setUserInfoToLocalStorage(accessToken, refreshToken, email, uid) {
  const userInfo = { accessToken, refreshToken,  email, uid };
  localStorage.setItem("userInfo", JSON.stringify(userInfo));
}

export function getUserInfoFromLocalStorage() {
  const userInfoString = localStorage.getItem("userInfo");
  if (userInfoString) {
    return JSON.parse(userInfoString);
  } else {
    return null;
  }
}

export function removeUserInfoFromLocalStorage() {
  localStorage.removeItem("userInfo");
}
