import './Error.scss';
import {
    useNavigate,
  } from "react-router-dom";

function Error(){
    const navigate = useNavigate();

    function redirectToMain(){
      return navigate('/', { replace: true });
    }

    return (
    <div className="error-wrapper">
    <div className="error-content">
        <h1 className="error-content__header">Error</h1>
        <p className="error-content__text">An unexpected error occurred</p>
        <button className="error-content__button" onClick={redirectToMain}>
        <span className='error-content__button-text'
        >Go Home</span></button>
    </div>
    </div>)
}

export default Error;