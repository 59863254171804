export async function validateToken(accessToken) {
    const url = `${process.env.REACT_APP_BACKEND_API}/introspect`;
  
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accessToken
      })
    });
    
    const data = await response.json();
    return data;
}

export async function refreshAccessToken(refreshToken) {
  const url = `${process.env.REACT_APP_BACKEND_API}/refreshToken`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
       refreshToken
    })
  });


  const data = await response.json();
  return data;
}

export async function getAccountInfo(accessToken) {
  const url = `${process.env.REACT_APP_BACKEND_API}/getAccountInfo`;
  
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken
    })
  });

  const data = await response.json();
 
  return { email: data?.profile?.email, uid: data?.UID};

}

// https://testdeploy2-410616.uc.r.appspot.com/token
// 
async function getAccessToken(code){
  if (code) {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/getToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'code': JSON.stringify(code),
        },

        body: JSON.stringify({
          code
        })
      });


        const responseData = await response.json();
        return responseData;

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
};

export async function autorizeUser(code){
  const tokensData = await getAccessToken(code);
  const { access_token, refresh_token } = tokensData;

  const accountInfo = await getAccountInfo(access_token)
  
  console.log(access_token, refresh_token, accountInfo?.email, accountInfo?.uid, 'autorizeUser')
  return {access_token, refresh_token, email: accountInfo?.email, uid: accountInfo?.uid}
}



















  /*
      <BrowserRouter>{
      (false)? (<Routes>
        <Route path="/login" element={<LoginPage onLogIn={setUserProfile} />} />
        <Route path="/consent" element={<ConsentPage />} />
        <Route path="/proxy" element={<div>TTTTTTTTTTT</div>} />
        <Route path="/register" element={<RegisterPage/>}/>
        <Route path="/error" element={<ErrorPage/>} />
        <Route
          path="/"
          element={
            <RequireAuth userStatus={userProfileFake}>
              <BaseLayout onSignOut={setUserProfile} userProfile={userProfileFake}></BaseLayout>
            </RequireAuth>
          }
        >
          <Route index element={ <Navigate to="/send-reports" replace />} />
          <Route path="/send-reports" element={<FileUpload />} />
          <Route path="/history" element={<div>History</div>} />
          <Route path="/*" element={<Navigate to="/send-reports" replace />} />
        </Route>
    </Routes>): <CircularIndeterminate/>
    }
  </BrowserRouter>
  */