import { useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const ConsentPage = () => {
    const [searchParams] = useSearchParams();
    useLayoutEffect(() => {
        (async () => {
            const context = searchParams.get('context');
            const clientID = searchParams.get('clientID');
            const scope = searchParams.get('scope');
            const UID = searchParams.get('UID');

            const settings = {
                scope: scope?.replace(/[+]/g, ' '),
                clientID,
                context,
                UID,
                consent: true,
            };

            const consentObj = JSON.stringify(settings);
            const rawResponse = await fetch(`${process.env.REACT_APP_BACKEND_API}/calcSignature`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    consentObj,
                }),
            });

            const content = await rawResponse.json();
            const userKey = `${process.env.REACT_APP_USER_KEY}`;
            const newUrl = `proxy?mode=afterConsent&consent=${consentObj}&sig=${content.signature}&userKey=${userKey}`;
            window.location.href = newUrl;
        })();
    }, [searchParams]);

    return null;
};


export default ConsentPage;