import { isJSON } from '../utils/typesChecker';

export const useSessionStorage = (storageKey) => {
    const getStoredValue = (key) => {
        const valueFromStorage = sessionStorage.getItem(storageKey);

        const storedValue = isJSON(valueFromStorage) ? JSON.parse(valueFromStorage) : valueFromStorage;
        return key ? storedValue?.[key] : storedValue;
    };
    const saveValueToStore = (value, key) => {
        const storedValue = getStoredValue() || {};
        const valueToStore = key && { ...storedValue, [key]: value };
        sessionStorage.setItem(storageKey, JSON.stringify(key ? valueToStore : value));
    };
    const removeValueFromStore = () => sessionStorage.removeItem(storageKey);

    return [getStoredValue, saveValueToStore, removeValueFromStore];
};

export const useLocalStorage = (storageKey) => {
    const getStoredValue = (key) => {
        const valueFromStorage = localStorage.getItem(storageKey);

        const storedValue = isJSON(valueFromStorage) ? JSON.parse(valueFromStorage) : valueFromStorage;
        return key ? storedValue?.[key] : storedValue;
    };
    const saveValueToStore = (value, key) => {
        const storedValue = getStoredValue() || {};
        const valueToStore = key && { ...storedValue, [key]: value };
        localStorage.setItem(storageKey, JSON.stringify(key ? valueToStore : value));
    };
    const removeValueFromStore = () => localStorage.removeItem(storageKey);

    return [getStoredValue, saveValueToStore, removeValueFromStore];
};