import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";


function LoginPage({ status }) {

    useEffect(() => {
      if (window.gigya && status === "FAIL") {
        window.gigya.accounts.showScreenSet({
          screenSet: "bayer-RegistrationLogin",
          startScreen:'bayer-register-nosc',
          onAfterSubmit: (response) => {
            console.log(response, window.gigya, "response");
            window.location.reload();
          },
        });
      }
    });
    if (status === "OK") {
      //temporary
      return <Navigate to="/" />;
    }
  
    return <></>;
  }
  export default LoginPage;