import React, { useEffect, useRef } from "react";
import "./PopUp.scss";


function PopUp({ isActive, children, onClosePopUp, id }) {
  const countRef = useRef(0);

  useEffect(() => {
    const handleClickOutside = (event) => {
      countRef.current = Number(countRef.current) + 1;

      //condition countRef.current>1 do not close the popup during the opening process
      if (event.target.id !== id && countRef.current > 1) {
        onClosePopUp();
      }
    };

    if (isActive) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
      countRef.current = 0;
    };
  });

  return (
    <div id={id} className={`pop-up ${isActive ? "--active" : "--inactive"}`}>
      {children}
    </div>
  );
}

export default PopUp;
