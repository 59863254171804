export const isNumber = (value) => typeof value === 'number' && !isNaN(value);
export const isString = (value) => typeof value === 'string';
export const isBoolean = (value) => typeof value === 'boolean';
export const isFunction = (value) => typeof value === 'function';
export const isUndefined = (value) => typeof value === 'undefined';
export const isObject = (value) => typeof value === 'object';
export const isArray = (value) => Array.isArray(value);
export const isJSON = (value) => {
    try {
        JSON.parse(value);
    } catch (e) {
        return false;
    }
    return true;
};
