import "./FileLoader.scss";
import React, { useState, useEffect } from "react";
import MyDropzone from "../Dropzone/Dropzone.jsx";
import BasicModal from "../ModalWindow/ModalWindow";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import FileCard from "../FileCard/FileCard.jsx";
import {
  SuccessResultWindow,
  WrongResultWindow,
} from "../ResultWindow/ResultWindow.jsx";
import {
  getUserInfoFromLocalStorage,
  setUserInfoToLocalStorage,
} from "../../utils/localStorageInterect.js";
import { useNavigate } from "react-router-dom";
import {
  validateToken,
  refreshAccessToken,
  getAccountInfo,
} from "../../../src/api/gigyaApi";

import axios from "axios";

function FileUpload({ userProfile }) {
  const [files, setFiles] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [sendingResult, setSendingResult] = useState(null);
  const navigate = useNavigate();
  const handleDropedFiles = (newData) => {
    setFiles((prevFiles) => [...prevFiles, ...newData]);
  };
  const { accessToken, refreshToken, email, uid } =
    getUserInfoFromLocalStorage();

  const handleUpload = () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file, file.name);
    });

    console.log(formData, "formData");

    setSendingResult("LOAD");

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error) {
        if (error.response && error.response.status === 401) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_BACKEND_API}/refreshToken`,
            data: JSON.stringify({ refreshToken }),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              if (response.data?.access_token) {
                setUserInfoToLocalStorage(
                  response.data?.access_token,
                  response.data?.refresh_token,
                  email,
                  uid
                );
                error.config.headers["accessToken"] =
                  response.data?.access_token;
                return axios.request(error.config).then(function (response) {
                  // Handle successful file ingest
                  console.log(response, "response-11");

                  if (response) {
                    setSendingResult("OK");
                    setFiles([]);
                  }
                });
              }
            })
            .catch((error) => {
              console.error("Error refreshing access token", error);
              window.location.reload();
              return null;
            });
        } else {
          // If the error is not 401, just forward it
          return Promise.reject(error);
        }
      }
    );

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_API}/ingest_file?mail=${userProfile?.email}`,
      headers: {
        Accept: "application/json",
        accessToken: `${accessToken}`,
      },
      data: formData,
    })
      .then(function (response) {
        if (response) {
          setSendingResult("OK");
          setFiles([]);
        }
      })
      .catch(function (error) {
        console.error("Error during file ingest", error);
      });
  };

  const handleDownload = async (fileName) => {
    console.log(fileName, "fileName");
    try {
      const response = await fetch(
        `http://127.0.0.1:5000/download?file_name=${fileName.name}`
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } else {
        console.error("File download failed.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleRemoveFile = (fileName) => {
    const updatedArr = files.filter((file) => file.name !== fileName);
    setFiles(updatedArr);
  };

  return (
    <div className="load-page">
      <h1 className="load-page__title">{`Report Submission`}</h1>
      <p className="load-page__description">{`You can use this page to submit your sales
       report and inventory report files to Bayer.`}</p>
      <MyDropzone onDropFiles={handleDropedFiles} />
      <div className="attached-files">
        {files.map(({ name, size }) => (
          <FileCard
            fileName={name}
            size={size}
            onRemove={handleRemoveFile}
            LoadStatus={sendingResult}
          />
        ))}
      </div>

      <div className="submit">
        <Button
          disabled={!files.length || sendingResult === "LOAD"}
          variant="contained"
          onClick={handleUpload}
          sx={{
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "24px",
            textTransform: "none",
          }}
        >
          Submit Report{files.length > 1 ? "s" : " "}
        </Button>
      </div>

      {!!uploadedFiles.length && (
        <div>
          <h2>list of sent documents</h2>
          <div className="saved-list">
            {uploadedFiles.map((file) => (
              <Chip
                label={file}
                variant="outlined"
                onClick={() => handleDownload(file)}
                key={file}
              ></Chip>
            ))}
          </div>
        </div>
      )}
      <BasicModal open={open} onAction={setOpen} />

      {sendingResult === "OK" && (
        <SuccessResultWindow
          onClose={setSendingResult}
          sendingResult={sendingResult}
        />
      )}
      {sendingResult === "FAIL" && (
        <WrongResultWindow
          onClose={setSendingResult}
          sendingResult={sendingResult}
        />
      )}
    </div>
  );
}

export default FileUpload;
