import "./Header.scss";
import React, { useEffect, useState } from "react";
import LogosSvg from "../../assets/icons/Logos.svg";
import DefaultUserIcon from "../../assets/icons/DefaultUserIcon.svg";
import PopUp from "../PopUp/PopUp";
import SignOutIcon from "../../assets/icons/SignOutIcon.svg";
import {
  useNavigate,
} from "react-router-dom";

function Header({ userProfile, onSignOut }) {
  const [userProfilePopUpStatus, setUserProfilePopUpStatus] = useState(false);
  const navigate = useNavigate()

  function handleSignOut(){
    onSignOut();
    return navigate('/', { replace: true });
  }

  return (
    <header>
      <div className="logo">
        <img className="logo__icon" src={LogosSvg} alt="logo"></img>
        <span className="logo__description">Bayer CISO Toolbox</span>
      </div>

      <div
        className={`user-profile ${userProfilePopUpStatus ? "--active" : ""}`}
      >
        <img
          className="user-profile__icon"
          src={DefaultUserIcon}
          alt="logo"
        ></img>
        <span
          className="user-profile__description"
          onClick={() => setUserProfilePopUpStatus(true)}
        >
          {userProfile?.email}
        </span>
        <div className="user-profile__pop-up pop-up">
          <PopUp
            isActive={userProfilePopUpStatus}
            onClosePopUp={() => setUserProfilePopUpStatus(false)}
            id="user-profile-pop-up"
          >
            <div onClick={handleSignOut} className="pop-up__sign-out">
              <span>Sign out</span>
              <img src={SignOutIcon} alt="sign-out-icon" />
            </div>
          </PopUp>
        </div>
      </div>
    </header>
  );
}

export default Header;
