
import "./ConsentStatement.scss"

function ConsentStatement() {
  return (
    <div className="consent-info-page">
      <div class="container">
        <h1>Privacy Statement</h1>
        <p>
          This website (hereinafter the “Website“) is provided by Bayer
          Aktiengesellschaft (hereinafter “us” or “we”). For further information
          regarding the provider of the Website, please refer to our imprint{" "}
          <a href="https://www.bayer.com/en/imprint" target="_blank" rel="noreferrer">
            https://www.bayer.com/en/imprint
          </a>
          .
        </p>
        <p>
          Information on other selected non-Website related data processing
          activities performed by us and all of our affiliates in Germany (e.g.,
          when you give us a business card or we collect your data from publicly
          available sources) can be found in the privacy information for
          selected specific processing activities.
        </p>

        <h2>Handling of Personal Data</h2>
        <p>
          In the following we wish to provide you with information on how we
          handle your personal data when you use our Website. Unless otherwise
          indicated in the following chapters, the legal basis for the handling
          of your personal data results from the fact that such handling is
          required to make available the functionalities of the Website
          requested by you (Art. 6(1)(b) General Data Protection Regulation).
        </p>

        <h2>Using Our Website</h2>
        <h3>Accessing Our Website</h3>
        <p>
          When you call up our Website, your br/owser will transfer certain
          information to our web server. This is done for technical reasons and
          required to make available to you the requested information. To
          facilitate your access to the Website, the following information is
          collected, br/iefly stored, and used:
        </p>
        <ul>
          <li>IP address</li>
          <li>Date and time of access</li>
          <li>Time zone difference to Greenwich Mean Time (GMT)</li>
          <li>Content of request (specific site)</li>
          <li>Status of access/HTTP status code</li>
          <li>Transferred volume of data</li>
          <li>Website requesting access</li>
          <li>
            Br/owser, language settings, version of br/owser software operating
            system and surface
          </li>
        </ul>
        <p>
          Moreover, to protect our legitimate interests, we will store such
          information for a limited period of time in order to be able to
          initiate a tracking of personal data in the event of actual or
          attempted unauthorized access to our servers (Art. 6(1)(f) General
          Data Protection Regulation).
        </p>

        <h3>Registration and Login</h3>
        <p>
          In order to be able to use certain services of our Website, you need
          to first register an account with us, where you are able to determine
          your personal login credentials that you need to be able to log into
          your personal account. For this registration and subsequent login
          procedure, we collect the following information about you:
        </p>
        <ul>
          <li>Name and surname</li>
          <li>Contact data (e.g., postal/email address or phone number)</li>
          <li>User name and password</li>
          <li>Log file of your logins incl. timestamp</li>
        </ul>
        <p>
          We process this personal data in order to provide you with access to
          the services of our Website. They are deleted in case you deactivate
          your user account.
        </p>

        <h3>Setting of Cookies</h3>
        <h4>What are Cookies?</h4>
        <p>
          This website uses so-called “cookies”. Cookies are small text files
          that are stored in the memory of your terminal via your br/owser. They
          store certain information (e.g., your preferred language or site
          settings) which your br/owser may (depending on the lifespan of the
          cookie) retransmit to us upon your next visit to our website.
        </p>

        <h4>What Cookies Do We Use?</h4>
        <p>
          We differentiate between two main categories of cookies: (1) strictly
          necessary cookies, which are essential to br/owse the website and use
          its features, and (2) optional cookies (e.g., analytic cookies,
          targeting cookies, functional cookies) used for e.g., website
          analysis, website personalization, and marketing purposes.
        </p>

        <h4>Optional Cookies</h4>
        <p>This website does not use optional cookies.</p>

        <h4>Subject to Your Consent</h4>
        <p>
          We only use optional cookies if we have obtained your prior consent
          (Art. 6(1)(a) General Data Protection Regulation). Upon your first
          access to our website, a banner will appear, asking you to give us
          your consent to the setting of optional cookies. If your consent is
          given, we will place a cookie on your computer and the banner will not
          appear again as long as the cookie is active. After the expiration of
          the cookie’s lifespan, or if you actively delete the cookie, the
          banner will reappear upon your next visit to our website and again ask
          for your consent.
        </p>

        <h2>Recipients of Personal Data</h2>
        <h3>Commissioned Processing</h3>
        <p>
          For the processing of your personal data we will to some extent use
          specialized service contractors that process your data on our behalf
          (e.g., for IT support or cloud services). This includes in particular
          also:
        </p>
        <ul>
          <li>
            EPAM Systems GmbH
            <br />
            Friedrich-Ebert-Anlage 49
            <br />
            60308 Frankfurt am Main
            <br />
            tel:+496931019090
            <br />
            <a href="mailto:SupportPMOBayer@epam.com">
              SupportPMOBayer@epam.com
            </a>
          </li>
          <li>
            NTT DATA
            <br />
            Av. d'Icària, 211
            <br />
            08005 Barcelona
            <br />
            Spain
          </li>
          <li>
            Cognizant Technology Solutions GmbH
            <br />
            Speicherstrasse 57-59
            <br />
            60327 Frankfurt am Main
          </li>
        </ul>
        <p>
          Such service contractors are carefully selected and regularly
          monitored by us. Based on respective data processing agreements, they
          will only process personal data in accordance with our instructions.
        </p>

        <h3>Affiliates</h3>
        <p>
          We may share your personal data with our affiliates from the Bayer
          Group, where necessary for the purposes described above.
        </p>

        <h3>Authorities and State Institutions</h3>
        <p>
          We may share your personal data with law enforcement agencies or other
          authorities and state institutions if legally required or necessary
          for the purposes described above.
        </p>

        <h3>External Lawyers</h3>
        <p>
          In order to support legal decisions and to pursue or defend against
          legal claims, we may share your personal data with external lawyers.
        </p>

        <h3>Prospective Buyers in the Context of Mergers & Acquisitions</h3>
        <p>
          We may share your personal data with a prospective buyer in case of an
          acquisition, merger, or any other type of corporate or asset
          transition involving a change of ownership or control concerning us or
          our services.
        </p>

        <h2>Information Regarding Your Rights</h2>
        <p>
          The following rights are in general available to you according to
          applicable data privacy laws:
        </p>
        <ul>
          <li>Right of information about your personal data stored by us;</li>
          <li>
            Right to request the correction, deletion, or restricted processing
            of your personal data;
          </li>
          <li>
            Right to object to processing based on legitimate interest or public
            interest, unless we are able to prove that compelling, warranted
            reasons superseding your interests, rights, and freedom exist, or
            that such processing is done for purposes of the assertion,
            exercise, or defense of legal claims;
          </li>
          <li>Right to data portability;</li>
          <li>Right to file a complaint with a data protection authority;</li>
          <li>
            Where you have provided your consent to the processing of your
            personal data, you may at any time withdraw your consent with future
            effect. Such a withdrawal will not affect the lawfulness of the
            processing prior to the withdrawal.
          </li>
        </ul>

        <div class="contact-info">
          <h2>Contact</h2>
          <p>
            For any questions you may have with respect to data privacy, or if
            you wish to exercise your rights, please address your request to our
            contact form or contact our company data protection officer at the
            following address:
          </p>
          <p>
            Data Privacy Officer
            <br />
            Bayer AG
            <br />
            Kaiser-Wilhelm-Allee 1<br />
            51368 Leverkusen, Germany
          </p>
          <p>
            Bayer AG is designated as a representative in the European Union for
            our non-European legal entities in accordance with Art. 27 GDPR. You
            may contact the representative at the following address:
          </p>
          <p>
            Data Privacy Representative
            <br />
            Bayer AG
            <br />
            Kaiser-Wilhelm-Allee 1<br />
            51368 Leverkusen, Germany
            <br />
            Email:{" "}
            <a href="mailto:dp-representative@bayer.com">
              dp-representative@bayer.com
            </a>
          </p>
        </div>

        <div class="amendment-info">
          <h2>Amendment of Privacy Statement</h2>
          <p>
            We may update our Privacy Statement from time to time. Updates of
            our Privacy Statement will be published on our website. Any
            amendments become effective upon publication on our website. We
            therefore recommend that you regularly visit the site to keep
            yourself informed on possible updates.
          </p>
        </div>
      </div>
    </div>
  );
}


export default ConsentStatement;