import {
  validateToken,
  refreshAccessToken,
  getAccountInfo,
} from "api/gigyaApi";

import {
  setUserInfoToLocalStorage,
  getUserInfoFromLocalStorage,
} from "utils/localStorageInterect";

async function validateSession(setSessionStatus, setUserProfile) {
  try {
    const savedData = getUserInfoFromLocalStorage();
    if (!savedData) {
      setSessionStatus("FAIL");
      return;
    }

    const { accessToken, refreshToken, email, uid } = savedData;
    if (
      (await isValidSession(accessToken)) ||
      (await tryRefreshingTokens(refreshToken))
    ) {
      setUserProfile({ email, uid });
      setSessionStatus("OK");
    } else {
      setSessionStatus("FAIL");
    }
  } catch (error) {
    console.error("Failed to validate session:", error);
    setSessionStatus("FAIL");
  }
}

async function isValidSession(token) {
  const validToken = await validateToken(token);
  return validToken?.active;
}

async function tryRefreshingTokens(refreshToken) {
  const response = await refreshAccessToken(refreshToken);
  if (!response?.access_token || !response?.refresh_token) return false;

  const validateNewToken = await isValidSession(response.access_token);
  if (!validateNewToken) return false;

  const { email, uid } = await getAccountInfo(response.access_token);
  setUserInfoToLocalStorage(
    response.access_token,
    response.refresh_token,
    email,
    uid
  );
  return true;
}

export default validateSession;